<template>
  <v-dialog
    v-model="dialogObservacao"
    @click:outside="$emit('update:dialogObservacao', false)"
    @keydown.esc="$emit('update:dialogObservacao', false)"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title dark class="text-center tocs_gray_1 text-body-1">
        {{ $tc("global.nova") }} {{ $tc("global.observacao") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-textarea
          v-model="observacao"
          :placeholder="$tc('global.observacao')"
          outlined
          dense
          height="90px"
          class="input-low-height mx-4 mt-4"
          hide-details
        ></v-textarea>
        <v-text-field
          @keydown.enter="postNovaObservacao"
          v-model="status"
          :placeholder="$tc('global.status')"
          outlined
          dense
          height="10px"
          class="input-low-height mx-4 mt-4"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          small
          class=""
          color="tocs_gray_2"
          text
          depressed
          @click="$emit('update:dialogObservacao', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          small
          depressed
          color="button_1"
          class="white--text"
          @click="postNovaObservacao"
        >
          <v-icon left small class="mx-1">mdi-content-save</v-icon>
          {{ $tc("global.salvar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { postObservacaoGeral } from "@/api/observacoes-gerais/observacoes_gerais.js";

export default {
  name: "DialogObservacao",

  props: {
    dialogObservacao: {
      type: Boolean,
      default: false,
    },
    origem_id: {
      type: Number,
      default: 0,
    },
    origem: {
      type: String,
      default: "",
    },
  },

  components: {
  },

  data() {
    return {
      observacao: "",
      status: "VENDA",
      responsavel: "",
    };
  },

  watch: {
  },

  computed: {}, 

  methods: {
    postNovaObservacao() {
      postObservacaoGeral({
        observacao: this.observacao,
        status: this.status,
        origem: this.origem,
        origem_id: this.origem_id,
      }).then((response) => {
        this.$emit("close");
      });
    }
  },

  async mounted() {
    if (this.origem == "ORDEM_SERVICO") this.status = "OS";
  },
};
</script>

<style></style>
